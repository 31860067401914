.list-button:hover .icon {
  transition: all 0.2s ease;
  color: white !important;
}

// .checkbox-item {
//   opacity: 0;
//   transition: 0.2s ease all;

//   &.checked {
//     opacity: 1;
//   }
// }

.item:hover .checkbox-item {
  opacity: 1;
}
