@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
  height: 100%;
  font-family: "Poppins", sans-serif;
}

input[type="date"]:not(.start-input):not(.end-input) {
  position: relative;
  user-select: none;

  &::-webkit-calendar-picker-indicator, &::-webkit-inner-spin-button {
    // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23fff" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}

.start-input, .end-input {
  padding-inline-start: 0!important;

  &::-webkit-calendar-picker-indicator, &::-webkit-inner-spin-button {
    // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23fff" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}

input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent!important;
}

input[type=date]:focus::-webkit-datetime-edit {
  color: #fff !important;
}
::-webkit-datetime-edit,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field {
  color: transparent!important;
}

label.chakra-form__label {
  padding-inline-start: 30px;
}

input.chakra-input:not([type="search"]) {
  padding-inline-start: 30px;
}

select.chakra-select {
  padding-inline-start: 30px;

}

.chakra-select__icon-wrapper {
  color: #82848d!important;
  font-size: 26px!important;
  right: 20px!important;
}

@media(max-width: 640px) {
  label.chakra-form__label {
    padding-inline-start: 20px;
  }

  input.chakra-input:not([type="search"]) {
    padding-inline-start: 20px;
  }

  select.chakra-select {
    padding-inline-start: 20px;
  }
}

.infinite-scroll-component {
  overflow: unset!important;
}

.leaflet-container {
	height: 100%;
	width: 100%;
}
